.typing-container {
  display: inline-flex;
  align-items: left;
  gap: 4px;
}

.cursor {
  font-weight: bold;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.typed-text {
  white-space: nowrap;
}

.background-image {
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.home__moving-background {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: translateY(-50%);
    will-change: transform;
    transition: transform 0.1s ease-out;
  }

  @media (max-width: $breakpointMobile) {
    height: 250px;

    img {
      width: 140%;
      left: -20%;
    }
  }
}

.home__intro-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: $breakpointMobile) {
      width: 175%;
      position: relative;
      object-position: center right;
    }
  }
}
