// General styles
$fontFamily: 'Ubuntu Sans' !important;
$fontFamilyMono: 'Ubuntu Mono' !important;
$borderBoxShadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

// Colors
$primary: #b82633;
$black: #000;
$white: #fff;
$grey: #959595;

// Font sizes
$fontSizeTitle: 64px;
$fontSizeSubtitle: 48px;
$fontSizeHeadline: 36px;
$fontSizeLarge: 32px;
$fontSizeMedium: 24px;
$fontSizeNormal: 20px;
$fontSizeSmall: 16px;

$fontWeightBold: 800;
$fontWeightSemiBold: 600;
$fontWeightMedium: 500;
$fontWeightRegular: 400;
$fontWeightLight: 300;

// Breakpoints
$breakpointMobile: 860px;
