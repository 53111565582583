.contact {
  min-height: 65vh;
  position: relative;
  padding: 190px 20px 80px;
  overflow: hidden;
  z-index: 1;
  background-color: $white;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 0;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__form-container {
    background-color: rgba(245, 245, 245, 0.95);
    border-radius: 35px;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    margin: 0 auto;
  }

  &__content {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 60px;
    align-items: start;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      max-width: 600px;
      margin: 0 auto;
      gap: 40px;
    }
  }

  &__text {
    color: $white;
    padding-right: 40px;

    @media (max-width: 1024px) {
      padding-right: 0;
      text-align: center;
      max-width: 100%;
    }
  }

  &__title {
    font-size: $fontSizeSubtitle;
    font-weight: 700;
    margin-bottom: 32px;
    color: $white;
  }

  &__description {
    p {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 24px;
      color: $black;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    width: 100%;
    box-sizing: border-box;
  }

  &__form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  &__form-group {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      display: block;
      margin-bottom: 8px;
      color: $black;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }

    input, textarea {
      width: 100%;
      max-width: 100%;
      padding: 12px 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 30px;
      background: white;
      color: $black;
      font-size: 16px;
      transition: all 0.3s ease;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: $primary;
        box-shadow: 0 0 0 2px rgba($primary, 0.1);
      }

      @media (max-width: 768px) {
        padding: 10px 16px;
        font-size: 14px;
      }
    }

    textarea {
      resize: vertical;
      min-height: 100px;
      border-radius: 20px;
      padding: 16px 20px;
    }
  }

  &__form-submit {
    margin-top: 32px;
    text-align: right;
    width: 100%;

    @media (max-width: 768px) {
      margin-top: 25px;
    }
  }

  &__info {
    margin-top: 24px;
    font-weight: 500;
    color: $primary;
  }

  &__email {
    color: $primary;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}
