.button {
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 46px;
  font-size: $fontSizeSmall;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  width: fit-content;
  min-width: min-content;

  &__text {
    white-space: nowrap;
    display: inline-block;
    padding: 0 5px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    
    &--hover {
      display: none;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $white;
    border-color: $white;
    box-shadow: none;

    &:hover {
      background-color: $white;
      color: $black;
      
      .button__icon {
        color: $black;
      }
    }
  }

  &.transparent-blue {
    background-color: transparent;
    color: $black;
    border-color: $black;
    box-shadow: none;
    padding: 0px 50px;

    &:hover {
      background-color: $black;
      color: $white;
      
      .button__icon {
        &--default {
          display: none;
        }
        
        &--hover {
          display: inline-flex;
        }
      }
    }
  }

  &.dark {
    background-color: rgba(0, 0, 0, 0.8);
    color: $white;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: $white;
      color: rgba(0, 0, 0, 0.8);
      border-color: rgba(0, 0, 0, 0.8);
      
      .button__icon {
        &--default {
          display: none;
        }
        
        &--hover {
          display: inline-flex;
        }
      }
    }
  }

  &.red {
    background-color: transparent;
    color: $primary;
    border-color: $primary;
    box-shadow: none;

    &:hover {
      background-color: $primary;
      color: $white;
      
      .button__icon {
        &--default {
          display: none;
        }
        
        &--hover {
          display: inline-flex;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
