.navbar {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 0 auto;
  max-width: 1200px;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;

  &--hidden {
    transform: translateY(-150%);
  }

  &__left {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__center {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__menu-dropdown {
    position: relative;
    
    &:hover .navbar__dropdown-list,
    .navbar__hover-bridge:hover + .navbar__dropdown-list,
    .navbar__dropdown-list:hover {
      opacity: 1;
      visibility: visible;
      max-height: 300px;
      padding: 15px 20px;
    }
  }

  &__hover-bridge {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -50px;
    left: 0;
  }

  &__navitem {
    cursor: pointer;
    font-size: $fontSizeSmall;
    color: $white;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 8px 16px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__dropdown-list {
    position: absolute;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
    min-width: 100px;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  &__dropdown-item {
    display: block;
    color: $white;
    padding: 6px 0px;
    font-size: $fontSizeSmall;    
    transition: background-color 0.2s;
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__mobile-toggle {
    display: none;
  }

  &__mobile-button,
  &__mobile-language {
    display: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    backdrop-filter: blur(4px);
  }

  &__divider {
    height: 24px;
    width: 1px;
    background-color: white;
    margin: 0 8px;
  }

  @media (max-width: $breakpointMobile) {
    &__center {
      position: fixed;
      top: 0;
      right: -100%;
      width: 40%;
      height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      padding: 100px 0 0 20px;
      transition: right 0.3s ease-in-out;
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      gap: 20px;
      align-items: flex-start;
      background-color: white;

      &.open {
        right: 0;
      }
    }

    &__right {
      display: none;
    }

    &__mobile-toggle {
      display: block;
      cursor: pointer;
    }

    &__mobile-button,
    &__mobile-language {
      display: block;
    }

    &__mobile-button {
      order: 1;
    }

    &__mobile-language {
      order: 2;
    }

    &__dropdown-list {
      position: static;
      transform: none;
      left: 0;
      box-shadow: none;
      width: 100%;

      &.open {
        opacity: 1;
        visibility: visible;
        max-height: 300px;
        padding: 10px 0;
      }
    }

    .mobile-only-dropdown-icon {
      display: inline-block;
      margin-left: 10px;
      transition: transform 0.3s ease;

      &.open {
        transform: rotate(180deg);
      }
    }

    &__navitem {
      color: $black;
    }

    &__dropdown-item {
      color: $black;
    }

    &__divider {
      display: none;
    }
  }
}
