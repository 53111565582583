.intro {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  overflow: hidden;
  z-index: 1;
  
  &__content {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
      font-size: $fontSizeTitle;
      font-weight: $fontWeightSemiBold;
      margin: 20px auto;
      color: $white;

      @media (max-width: $breakpointMobile) {
        font-size: $fontSizeLarge;
        padding: 0 15px;
        word-wrap: break-word;
      }
      animation: fadeIn 0.8s ease-in;
    }
    
    p {
      font-size: $fontSizeSubtitle;
      font-family: $fontFamilyMono;
      font-weight: $fontWeightMedium;
      margin-bottom: 40px;
      color: $white;
      margin-left: auto;
      margin-right: auto;
    
      @media (max-width: $breakpointMobile) {
        font-size: $fontSizeMedium;
        padding: 0 15px;
        word-wrap: break-word;
      }
      animation: fadeIn 0.8s ease-in 0.2s backwards;
    }
  }
  
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.8s ease-in 0.4s backwards;
    width: 100%;
    
    @media (max-width: $breakpointMobile) {
      flex-wrap: wrap;
      gap: 12px;
      
      button {
        min-width: 150px;
        font-size: 16px;
        padding: 15px;
      }
    }
  }
  

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.intro__scroll-arrow {
  position: absolute;
  bottom: 2rem;
  margin-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  cursor: pointer;
  animation: bounce 2s infinite;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.intro__scroll-arrow:hover {
  opacity: 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}
