@import './variables.scss';

body,
.body {
  font-family: 'Ubuntu' !important;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

// Home
@import '../pages/Home/Home.scss';
@import '../pages/Home/WhyAI/whyAI.scss';
@import '../pages/Home/OurServices/OurServices.scss';
@import '../pages/Home/Process/Process.scss';

// Components
@import '../components/NavBar/NavBar.scss';
@import '../components/Button/Button.scss';
@import '../components/Intro/Intro.scss';
@import '../components/Outro/Outro.scss';
@import '../components/Footer/Footer.scss';

// Contact
@import '../pages/Contact/Contact.scss';

// Our Projects
@import '../pages/OurProjects/OurProjects.scss';

// Stack
@import '../pages/Stack/Stack.scss';

// Not Found
@import '../pages/NotFound/NotFound.scss';
