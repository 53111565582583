.projects {
  position: relative;
  padding: 190px 20px 80px;
  min-height: 65vh;
  background-color: $white;
  overflow: hidden;
  z-index: 1;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: $breakpointMobile) {
        height: 110%;
      }
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    margin: 0 auto;
  }

  &__title {
    margin-top: 20px;
    font-size: $fontSizeTitle;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: $white;

  }

  &__description {
    text-align: center;
    font-size: $fontSizeLarge;
    color: rgba($black, 0.8);
    margin-bottom: 60px;

    @media (max-width: $breakpointMobile) {
      font-size: $fontSizeMedium;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    padding: 20px 0;
    max-width: 1400px;
    margin: 0 auto;
  }

  &__card {
    background: $white;
    border-radius: 20px;
    padding: 20px;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    border: 1px solid rgba($black, 0.1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 30px rgba($black, 0.1);
    }

    &-logo {
      height: 120px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      &-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        border-radius: 12px;
        background-color: inherit;
        
        img {
          max-width: 150px;
          max-height: 80px;
          width: auto;
          object-fit: contain;
        }
      }
    }

    &-content {
      text-align: center;
    }

    &-title {
      font-size: $fontSizeMedium;
      font-weight: 600;
      margin-bottom: 15px;
      color: $black;
    }

    &-description {
      font-size: $fontSizeSmall;
      color: rgba($black, 0.8);
      line-height: 1.6;
    }

    &-link {
      text-decoration: none;
      color: inherit;
      display: block;
      
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: $breakpointMobile) {
    padding: 150px 20px 60px;

    &__grid {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    &__card {
      padding: 20px;
    }
  }
}
