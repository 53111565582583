.our-services {
  padding: 60px 20px;
  
  h2 {
    font-size: $fontSizeHeadline;
    text-align: center;
    margin-bottom: 40px;
    color: $black;
  }
  &__grid {
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    row-gap: 50px;

    // Center the last two items
    & > *:nth-last-child(2) {
      grid-column: 1 / 2;
      transform: translateX(50%);
    }

    & > *:last-child {
      grid-column: 2 / 3;
      transform: translateX(calc(50% + 30px));
    }

    button {
      width: 200px;
    }
  }

  &__item {
    height: 460px;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakpointMobile) {
      width: auto;
    }
    
    h3 {
      margin: 15px 0;
      font-size: $fontSizeLarge;
      font-weight: $fontWeightRegular;
      color: $grey;
    }

    p {
      margin-top: 10px;
      min-height: 100px;
      color: $grey;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }

  &__icon {
    width: 145px;
    height: 145px;
    margin: 40px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__read-more {
    padding: 8px 24px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  &__popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 15px;
  }

  &__popup {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    position: relative;
    margin: 20px;
    max-height: 90vh;
    overflow-y: auto;

    h3 {
      color: #666;
      margin-bottom: 20px;
      padding-right: 40px;
    }

    p {
      color: #666;
      line-height: 1.6;
    }
  }

  &__popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 8px 12px;
    z-index: 1;

    &:hover {
      color: #333;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 20px;

    &__grid {
      grid-template-columns: 1fr;
      gap: 40px;
      max-width: 400px;
      
      // Reset transforms and positioning for mobile
      & > *:nth-last-child(2),
      & > *:last-child {
        grid-column: auto;
        transform: none;
      }
    }

    &__item {
      height: auto;
      min-height: 400px;
      margin: 0 auto;
      padding: 15px;
    }

    &__icon {
      margin: 20px;
    }

    &__popup-overlay {
      padding: 10px;
      align-items: flex-end;
    }

    &__popup {
      margin: 0;
      padding: 20px;
      width: 100%;
      max-height: 80vh;
      border-radius: 12px 12px 0 0;

      h3 {
        font-size: 1.2rem;
        margin-bottom: 15px;
      }

      p {
        font-size: 1rem;
        margin-bottom: 20px;
      }
    }

    &__popup-close {
      top: 10px;
      right: 10px;
      font-size: 28px;
    }
  }

  @media (max-width: 480px) {
    padding: 30px 20px;

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
