.notification {
  position: fixed;
  top: 180px;
  right: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;

  &--success {
    background-color: #4caf50;
    color: white;
  }

  &--error {
    background-color: #f44336;
    color: white;
  }

  &__close {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0 4px;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} 