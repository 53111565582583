.footer {
  padding: 60px 20px 20px;
  background-color: $black;
  color: $white;

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    gap: 80px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 60px;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__columns {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__section {
    min-width: 200px;

    @media (max-width: 1024px) {
      min-width: calc(50% - 20px);
    }

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  &__title {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightBold;
    margin-bottom: 20px;
  }

  &__company-info,
  &__address {
    p {
      margin-bottom: 5px;
      font-size: $fontSizeSmall;
    }
  }

  &__nav,
  &__legal {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 768px) {
      align-items: center;
    }
  }

  &__link {
    color: $white;
    text-decoration: none;
    font-size: $fontSizeSmall;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__copyright {
    margin-top: 60px;
    padding-top: 20px;
    border-top: 1px solid rgba($grey, 0.1);
    text-align: center;
    font-size: $fontSizeSmall;
    opacity: 0.8;
  }

  &__social {
    display: flex;
    gap: 16px;
    
    a {
      opacity: 0.8;
      transition: opacity 0.2s ease;
      
      &:hover {
        opacity: 1;
      }
    }
  }
}