.process {
  padding: 60px 20px;
  max-width: 1500px;
  margin: 0 auto;

  &__title {
    font-size: $fontSizeHeadline;
    font-weight: $fontWeightBold;
    text-align: center;
    margin-bottom: 100px;
  }
  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      width: 91%;
      height: auto;
      object-fit: contain;
    }
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    gap: 15px;
    
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__step {
    flex: 1;
    text-align: center;
    margin: 0 auto;

    &-title {
      display: none;
      font-size: $fontSizeLarge;
      font-weight: $fontWeightBold;
      color: $black;
      margin-bottom: 15px;
    }

    p {
      font-size: $fontSizeNormal;
      color: $grey;
      min-width: 320px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
      
      &-title {
        display: block;
      }
    }
  }

  &__why-us {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    h2 {
      font-size: $fontSizeHeadline;
      color: $black;
      margin-bottom: 20px;
    }

    p {
      font-size: $fontSizeNormal;
      color: $grey;
      line-height: 1.6;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 20px;

    &__image {
      margin-bottom: 40px;
    }

    &__why-us {
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 30px 15px;

    &__steps {
      gap: 30px;
    }
  }
}
