.stack {
  position: relative;
  padding: 190px 20px 80px;
  min-height: 65vh;
  background-color: $white;
  overflow: hidden;
  z-index: 1;

  &__title {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-size: $fontSizeTitle;
    font-weight: $fontWeightBold;
    color: $white;
    text-align: center;
    width: 100%;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.3s;
  }

  &__content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: $breakpointMobile) {
      margin-top: 150px;
    }
  }

  &__section {
    &-title {
      font-size: $fontSizeLarge;
      font-weight: 700;
      margin-bottom: 30px;
      color: $black;
      text-align: center;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  &__item {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-5px);
    }

    img {
      max-width: 80px;
      height: auto;
      object-fit: contain;
    }
  }

  @media (max-width: $breakpointMobile) {
    padding: 150px 20px 60px;

    &__grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    &__section-title {
      font-size: $fontSizeMedium;
      margin-bottom: 20px;
    }
  }
}
