.outro {
  min-height: 30vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  overflow: hidden;
  z-index: 1;

  &__description {
    max-width: 1000px;
    margin-bottom: 40px;
    font-size: $fontSizeLarge;
    font-weight: $fontWeightRegular;
    color: $white;

    @media (max-width: 768px) {
      font-size: $fontSizeNormal;
    }
  }
  
  &__buttons {
    
    margin-top: 40px;
    display: flex;
    gap: 40px;
    justify-content: center;
    
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  
  &__highlight {
    font-weight: 600;
  }
}
