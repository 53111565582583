.not-found {
  min-height: 110vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: $black;
  overflow: hidden;
  z-index: 1;
  
  &__content {
    position: relative;
    z-index: 2;
    max-width: 1000px;
    padding: 0 20px;
    
    h1 {
      font-size: 120px;
      font-weight: 700;
      margin-bottom: 0;
      color: $white;
      
      @media (max-width: 768px) {
        font-size: 80px;
      }
    }

    h2 {
      font-size: 52px;
      font-weight: 700;
      margin-bottom: 20px;
      color: $white;
      
      @media (max-width: 768px) {
        font-size: 36px;
      }
    }
    
    p {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 40px;
      color: $white;
      max-width: 900px;
      
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    a {
      text-decoration: none;
      display: inline-block;
    }
  }
  
  button {
    position: relative;
    z-index: 2;
    border: 2px solid $white;
    color: $white;
    font-weight: 500;
    padding: 12px 24px;
    font-size: 18px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: $white;
      color: $black;
    }
    
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
}
