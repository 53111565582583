.why-ai {
  position: relative;
  z-index: 2;
  background-color: $white;
  padding: 80px 20px 140px 20px;
  text-align: center;

  h2 {
    font-size: $fontSizeHeadline;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 60px;
    color: $black;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    gap: 25px;

    @media (max-width: $breakpointMobile) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__column {
    flex: 1;
    padding: 0 20px;

    h3 {
      font-size: $fontSizeMedium;
      font-weight: $fontWeightSemiBold;
      margin-bottom: 30px;
      color: $black;
    }

    p {
      font-size: $fontSizeNormal;
      line-height: 1.6;
      color: $grey;
    }

    @media (max-width: $breakpointMobile) {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  &__divider {
    width: 3px;
    height: 260px;
    border-left: 3px dashed $black;

    @media (max-width: $breakpointMobile) {
      width: 80%;
      height: 1px;
      border-left: none;
      border-bottom: 2px dashed $grey;
      margin: 20px 0;
    }
  }
}
